import React, { createContext, useContext } from 'react';
import { useAppSelector } from '../redux/hooks.js';
import { useRouteWeatherData } from './RouteWeatherProvider.js';
export const LocationContext = createContext([]);
export default function LocationListProvider({ children }) {
    const locations = useAppSelector((state) => state.location.route?.locationList) || [];
    const routeWeatherContext = useRouteWeatherData();
    let weatherEvents;
    function isWeatherEventsResponse(object) {
        return 'weatherEvents' in object;
    }
    if (Object.keys(routeWeatherContext).length &&
        isWeatherEventsResponse(routeWeatherContext) &&
        !Object.keys(routeWeatherContext.weatherEvents).length) {
        weatherEvents = routeWeatherContext.weatherEvents;
    }
    ;
    function getSnowDepth(coords, time) {
        let snowDepth = 0;
        if (!weatherEvents) {
            return snowDepth;
        }
        ;
        const latLngStr = `${coords.lat}_${coords.lng}`;
        const events = weatherEvents.get(latLngStr);
        const normalizedTime = new Date(time).toISOString();
        if (events) {
            events.some((result) => {
                const isMatch = result.time_in_utc === normalizedTime;
                if (isMatch)
                    snowDepth = result.snow_depth_in_cm;
                return isMatch;
            });
        }
        return snowDepth;
    }
    const enhancedLocations = locations.map((location) => {
        if (location.type === 'transition')
            return location;
        return {
            ...location,
            snowDepth: getSnowDepth(location.coords, location.time.arrivalTime),
        };
    });
    return (React.createElement(LocationContext.Provider, { value: enhancedLocations }, children));
}
;
export function useLocations() {
    return useContext(LocationContext);
}
;
