import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../constants/routes.js';
import { useAppSelector } from '../redux/hooks.js';
import { useAuthContext } from './AuthProvider.js';
export const RouteWeatherContext = createContext({});
export function RouteWeatherProvider({ visitCoords, children }) {
    const authContext = useAuthContext();
    // TODO: check if useMemo is required here
    // const visitCoords: LatLng[] = visits.map((loc) => loc.coords);
    const globalStartTimeISO8601 = useAppSelector((state) => state.simulation.result?.metrics.earliestVehicleStartTime) || '';
    const globalEndTimeISO8601 = useAppSelector((state) => state.simulation.result?.metrics.earliestVehicleStartTime) || '';
    const [weatherEventsResponse, setWeatherEventsResponse] = useState({});
    // TODO: Add in RouteGrid; modify RouteGrid to filter for visits
    // Visits are (1) reflected on the map and (2) passed to RouteWeatherProvider
    useEffect(() => {
        const weatherEventsRequest = {
            startTime: globalStartTimeISO8601,
            endTime: globalEndTimeISO8601,
            locations: visitCoords,
            snowDepthTrigger: 0,
        };
        const populateWeatherStore = async () => {
            try {
                const response = await axios.post(`${baseURL}/fetch-weather-events`, {
                    ...weatherEventsRequest,
                    uid: authContext.currentUser['uid'],
                    idToken: authContext.idToken,
                });
                if (response.status === 200) {
                    if (response.data && !response.data.weatherEvents?.length) {
                        throw new Error(`Received data from /fetch-weather-events but no weather events: ${JSON.stringify(response.data)}`);
                    }
                    if (response.data && !response.data.contiguousWeatherEvents?.length) {
                        throw new Error(`Received data from /fetch-weather-events but no contiguous weather events : ${JSON.stringify(response.data)}`);
                    }
                    try {
                        const weatherEventsMap = new Map(response.data.weatherEvents);
                        const contiguousWeatherEventsMap = new Map(response.data.contiguousWeatherEvents);
                        const weatherEvents = weatherEventsMap;
                        const contiguousWeatherEvents = contiguousWeatherEventsMap;
                        setWeatherEventsResponse({ weatherEvents, contiguousWeatherEvents });
                    }
                    catch (e) {
                        throw new Error(`Unable to convert weatherEvents from response into a map!: ${response.data.weatherEvents}`);
                    }
                }
            }
            catch (e) {
                throw new Error(`Failed to fetch weather events for ${JSON.stringify(weatherEventsRequest)}: ${e}`);
            }
        };
        if (process.env.SHOW_WEATHER_GRAPH) {
            populateWeatherStore();
        }
    }, [authContext, globalStartTimeISO8601, globalEndTimeISO8601, visitCoords]);
    return (React.createElement(RouteWeatherContext.Provider, { value: weatherEventsResponse }, children));
}
;
export function useRouteWeatherData() {
    return useContext(RouteWeatherContext);
}
;
