import { createSlice } from '@reduxjs/toolkit';
import { SIMULATION_STATUS } from '../constants/simulationStatus.js';
const initialState = {
    simulationId: null,
    status: SIMULATION_STATUS.pending,
};
export const simulationSlice = createSlice({
    name: 'simulation',
    initialState: initialState,
    reducers: {
        setReferenceLocation: (state, action) => {
            const { streetAddress, coords } = action.payload;
            state.referenceLocation = {
                streetAddress,
                coords,
            };
        },
        setRequest: (state, action) => {
            state.request = action.payload;
        },
        setRequestVisits: (state, action) => {
            if (state.request && state.request.optimizationRequest) {
                state.request.optimizationRequest.model.shipments = action.payload;
            }
        },
        setRequestVehicles: (state, action) => {
            if (state.request && state.request.optimizationRequest) {
                state.request.optimizationRequest.model.vehicles = action.payload;
            }
        },
        setResult: (state, action) => {
            state.result = action.payload;
        },
        setSnowDepthTrigger: (state, action) => {
            state.snowDepthTrigger = action.payload;
        },
        setSimulationId: (state, action) => {
            state.simulationId = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },
});
const { setReferenceLocation, setRequest, setRequestVehicles, setRequestVisits, setResult, setSnowDepthTrigger, setSimulationId, setStatus } = simulationSlice.actions;
export { setReferenceLocation, setRequest, setRequestVehicles, setRequestVisits, setResult, setSnowDepthTrigger, setSimulationId, setStatus };
export default simulationSlice.reducer;
