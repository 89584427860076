import React from "react";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { CalendarIcon, SparklesIcon, CheckCircleIcon } from "../constants/formIcons.js";
import AuthForm from "./AuthForm.js";
export default function StepperLandingStep({ handleSelection, selection }) {
    return (React.createElement(Stack, { direction: "column", spacing: 2, sx: { mt: 2, justifyContent: 'center' } },
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'center', gap: 2 } },
            React.createElement(Box, { sx: {
                    border: selection === 1 ? '2px solid blue' : 'none',
                    borderRadius: '4px',
                    p: 1
                } },
                React.createElement(Typography, { variant: "caption", display: "block", gutterBottom: true }, "Custom"),
                React.createElement(IconButton, { onClick: () => handleSelection(1), color: selection === 1 ? 'primary' : 'default' },
                    React.createElement("div", { style: { width: 50, height: 50 } },
                        React.createElement(CalendarIcon, null)),
                    selection === 1 ? (React.createElement(CheckCircleIcon, { isSelected: true })) : (React.createElement(CheckCircleIcon, { isSelected: false })))),
            React.createElement(Box, { sx: {
                    border: selection === 2 ? '2px solid blue' : 'none',
                    borderRadius: '4px',
                    p: 1
                } },
                React.createElement(Typography, { variant: "caption", display: "block", gutterBottom: true }, "Curated"),
                React.createElement(IconButton, { onClick: () => handleSelection(2), color: selection === 2 ? 'primary' : 'default' },
                    React.createElement("div", { style: { width: 50, height: 50 } },
                        React.createElement(SparklesIcon, null)),
                    selection === 2 ? (React.createElement(CheckCircleIcon, { isSelected: true })) : (React.createElement(CheckCircleIcon, { isSelected: false }))))),
        React.createElement(Divider, null),
        React.createElement(AuthForm, null)));
}
;
