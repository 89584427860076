import React, { useEffect, useState } from 'react';
import { Map as GMap, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

import LocationMarker from './LocationMarker.js';
import { useMapContext } from './MapProvider.js';

export default function Map(){
  const mapContext = useMapContext();
  const mapsLibrary = useMapsLibrary("geometry");
  const map = useMap();
  const [currentPolyline, setCurrentPolyline] = useState(null);
  const { setGlobalInfoWindowOpen } = mapContext;

  const defaultZoom = 11;
  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
  };
  useEffect(() => {
    if (!mapsLibrary || !map) {
      return;
    }
    if (mapContext.polylinePaths.length > 1) {
      throw new Error(`Multiple polyline paths were set!`);
    }

    if (mapContext.polylinePaths.length === 1) {
      const newPath = mapContext.polylinePaths[0];
      if (currentPolyline && newPath !== currentPolyline) {
        currentPolyline.setMap(null);
        setCurrentPolyline(null);
      }
      newPath.setMap(map);
      setCurrentPolyline(newPath);
    }

    return () => {
      if (currentPolyline) {
        currentPolyline.setMap(null);
      }
    };
  }, [currentPolyline, map, mapsLibrary, mapContext.polylinePaths]);

  const handleOutOfBoundsWindowClick = () => {
    setGlobalInfoWindowOpen(false);
  }

  return (
    <GMap
      onClick={handleOutOfBoundsWindowClick}
      defaultCenter={mapContext.center}
      defaultZoom={defaultZoom}
      options={mapOptions}
      mapId="a29c2f9ff638b8c0"
    >
      {mapContext.visits.map(visit => (
        <LocationMarker
          latLng={visit.coords}
          polylineHexColor={mapContext.polylineHexColor}
          streetAddress={visit.streetAddress}
          pinDisplay={visit.index}
        />
      ))}
    </GMap>
  );
};