import { distanceBetween } from 'geofire-common';
const allRadarSites = [
    [42.5867, -74.0641], [42.1991, -75.9848],
    [42.9483, -78.7366], [40.865, -72.8643],
    [44.9075, -84.7191], [42.6995, -83.4713],
    [42.8936, -85.5444], [46.5319, -87.5486],
    [41.9558, -71.137], [39.9462, -74.4115],
    [40.9234, -78.003], [40.5312, -80.2186],
    [44.5112, -73.1669], [43.8912, -70.2567],
    [46.07, -67.8], [43.8912, -70.2567],
    [40.865, -72.8643], [41.9558, -71.137],
    [39.9462, -74.4115], [43.8223, -91.1914],
    [42.9686, -88.5503], [44.4971, -88.1111],
    [44.8481, -93.5654], [46.8367, -92.2096],
    [43.8223, -91.1914], [41.4122, -81.8599],
    [39.4202, -83.8225], [39.7073, -86.2803],
    [41.3583, -85.7007], [38.2636, -87.7249],
    [52.52048, -107.44269],
    [45.70628, -73.85892],
    [50.548748, -101.08563],
    [49.28126, -81.79385],
    [55.694942, -119.230432],
    [43.372425, -81.380698],
    [50.571232, 105.182848],
    [45.949718, -60.205246],
    [46.222378, -65.699294],
    [48.551355, -77.808086],
    [47.247807, -84.596686],
    [51.20613, -113.399366],
    [50.312503, -110.195362],
    [49.858047, -92.797566],
    [47.326604, -53.12667],
    [46.449476, -71.913832],
    [48.025603, -53.0524],
    [45.428789, -75.658825],
    [45.658144, -65.517877],
    [44.741265, -81.30085],
    [48.972131, -123.707358],
    [44.632932, -63.505346],
    [49.026447, -122.285017],
    [45.578872, -62.654264],
    [46.628206, -81.307308],
    [49.274936, -112.717318],
    [48.375012, -89.350324],
    [48.606742, -87.707608],
    [52.105348, -106.642805],
    [46.246953, -63.091354],
    [45.893934, -66.47336],
    [48.518124, -68.561684],
    [43.810523, -79.099759]
];
export function validateLocation(latLng) {
    const testLocation = [latLng.lat(), latLng.lng()];
    const found = allRadarSites.some(site => {
        const distanceInKm = distanceBetween([site[0], site[1]], testLocation);
        if (distanceInKm <= 120)
            return true;
        return false;
    });
    return found;
}
