import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

import DeviceDetect from './DeviceDetect.js';
import Map from './Map.js';
import RouteGrid from './RouteGrid.js';
import MapProvider from './MapProvider.js';
import MemoizedRouteProvider from './RouteProvider.js';

export default function SimulationResult() {
    const { isMobile, isTablet } = DeviceDetect();
    const isDesktop = !isMobile && !isTablet;
    return (
        <div className="SimulationResult" style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }}>
            <div className="left-panel" style={{
                height: isDesktop ? '400px' : '50vh',
                paddingBottom: '10px',
                border: '1px solid red',
                boxSizing: 'border-box' 
            }}>
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                    <MapProvider>
                        <Map />
                    </MapProvider>
                </APIProvider>
            </div>
            <div className="right-panel" style={{
                flex: 1,
                paddingTop: '10px',
                border: '1px solid blue',
                overflowY: 'auto',
                boxSizing: 'border-box'
            }}>
                <MemoizedRouteProvider>
                    <RouteGrid />
                </MemoizedRouteProvider>
            </div>
        </div>
    );
};