export const SIMULATION_STATUS = {
    'pending': 0,
    'centerSelected': 1,
    'sitesSelected': 2,
    'vehiclesSelected': 3,
    'requestSent': 4,
    'requestSuccessful': 5,
    'requestFailed': 6,
};
export const colorMappingForRoute = {
    1: '#FF0000', // Red
    2: '#008000', // Green
    3: '#0000FF', // Blue
    4: '#FFA500', // Orange
    5: '#800080', // Purple
    6: '#008080', // Teal
    7: '#FF00FF', // Magenta
    8: '#00FF00', // Lime
    9: '#808000', // Olive
    10: '#000080', // Navy
    11: '#800000', // Maroon
    12: '#00FFFF', // Aqua
    13: '#FF7F50', // Coral
    14: '#006400', // Dark Green
    15: '#DAA520', // Goldenrod
    16: '#00008B', // Dark Blue
    17: '#A52A2A', // Brown
    18: '#5F9EA0', // Cadet Blue
    19: '#6A5ACD', // Slate Blue
    20: '#008B8B' // Dark Cyan
};
export const DEFAULT_ROUTE_HEX_COLOR = colorMappingForRoute[1];
