import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import { baseURL } from "../constants/routes.js";
import { useAuthContext } from "./AuthProvider.js";
import { SIMULATION_STATUS } from '../constants/simulationStatus.js';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import generateRequestTimeWindow from "../resources/simulationRequestTransforms.js";
import FirestoreListener from "./FirestoreListener.js";
import { setStatus } from "../redux/simulationSlice.js";
export default function StepperSubmitSimulation({ handleBack }) {
    const dispatch = useAppDispatch();
    const authContext = useAuthContext();
    const [simulationId, setSimulationId] = useState('');
    const [simulationUrl, setSimulationUrl] = useState('');
    const status = useAppSelector((state) => state.simulation.status) || SIMULATION_STATUS.vehiclesSelected;
    const [statusMessage, setStatusMessage] = useState('');
    const snowDepthTrigger = useAppSelector((state) => state.simulation.snowDepthTrigger);
    const globalStartTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalStartTime) || '';
    const globalEndTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalEndTime) || '';
    const shipments = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.shipments) || [];
    const vehicles = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.vehicles) || [];
    const defaultWeatherEvents = new Map();
    const weatherEvents = useAppSelector(state => state.location.weather?.weatherEvents) || defaultWeatherEvents;
    const defaultContiguousWeatherEvents = new Map();
    const contiguousWeatherEvents = useAppSelector(state => state.location.weather?.contiguousWeatherEvents) || defaultContiguousWeatherEvents;
    const optimizer = {
        "displayName": "",
        "modelSpec": {
            "globalStartTime": globalStartTimeISO8601,
            "globalEndTime": globalEndTimeISO8601,
        },
        "optimizeToursSpec": {
            "timeout": "300s",
            "populatePolylines": true,
            "populateTransitionPolylines": true
        }
    };
    useEffect(() => {
        if (status === SIMULATION_STATUS.requestSent) {
            setStatusMessage(`Thanks for submitting! It should take a 1-2 minutes to generate the routes. This page will automatically update once the result is ready.`);
        }
        else if (status === SIMULATION_STATUS.requestSuccessful) {
            setStatusMessage(`Your simulation is ready to view now!`);
        }
        else if (status === SIMULATION_STATUS.requestFailed) {
            setStatusMessage(`Your simulation failed due to an error in our application -- our engineering team will be looking into it.`);
        }
        else {
            setStatusMessage(`Once the simulation completes, your result will be ready in this link.`);
        }
    }, [status]);
    async function filterVisits() {
        if (!weatherEvents.size || !contiguousWeatherEvents.size) {
            console.log(`StepperSubmitSimulation: error handling weather events`);
            return [];
        }
        let updatedShipments = shipments.map((visit) => {
            const pickup = visit.pickups[0];
            const visitCoords = `${visit.pickups[0].arrivalLocation.latitude}_${visit.pickups[0].arrivalLocation.longitude}`;
            const shipmentWeatherEvents = weatherEvents.get(visitCoords) || [];
            const shipmentContiguousWeatherEvents = contiguousWeatherEvents.get(visitCoords) || [];
            const updatedTimeWindow = generateRequestTimeWindow(pickup, shipmentContiguousWeatherEvents, shipmentWeatherEvents);
            if (!updatedTimeWindow) {
                return null;
            }
            ;
            const updatedShipment = {
                ...visit,
                pickups: [{
                        ...pickup,
                        timeWindows: [updatedTimeWindow]
                    }]
            };
            return updatedShipment;
        }).filter(visit => visit !== null);
        return updatedShipments;
    }
    ;
    async function handleSubmit() {
        if (!authContext || !authContext.currentUser) {
            throw new Error(`Cannot submit without valid user credentials!: ${JSON.stringify(authContext)}`);
        }
        ;
        if (!snowDepthTrigger) {
            throw new Error(`Missing snow depth threshold!`);
        }
        if (!shipments.length || !vehicles.length) {
            throw new Error(`Missing vehicles or visits from the request!`);
        }
        // TODO: Ensure weather data is loaded in location store ready to go
        const updatedShipments = await filterVisits();
        const simulationRequest = {
            userId: authContext.currentUser['uid'],
            isFastest: true,
            simulationId: '', // set by server
            snowDepthTrigger: snowDepthTrigger,
            optimizer: optimizer,
            sites: updatedShipments,
            vehicles: vehicles,
        };
        const request = {
            ...simulationRequest,
            uid: authContext.currentUser['uid'],
            idToken: authContext.idToken,
        };
        try {
            const response = await axios.post(`${baseURL}/simulation-request`, request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200) {
                setSimulationId(response.data.simulationId);
                setSimulationUrl(response.data.simulationUrl);
            }
        }
        catch (e) {
            throw new Error(`Failed to submit simulation-request for uid ${authContext.currentUser['uid']}: ${e}`);
        }
        dispatch(setStatus(SIMULATION_STATUS.requestSent));
    }
    ;
    return (React.createElement(React.Fragment, null,
        simulationId &&
            React.createElement(FirestoreListener, { simulationId: simulationId, collectionId: 'results' }),
        React.createElement(Typography, null, statusMessage),
        status >= SIMULATION_STATUS.requestSent ? (React.createElement(React.Fragment, null,
            status === SIMULATION_STATUS.requestSent && React.createElement(LinearProgress, { style: { paddingTop: "10px" } }),
            status === SIMULATION_STATUS.requestSuccessful &&
                React.createElement(Button, { color: "primary", variant: "contained", onClick: () => window.open(simulationUrl, '_blank', 'noopener,noreferrer') }, "See result (new tab)"))) : (React.createElement(Stack, { direction: "row", gap: 2, paddingTop: "10px" },
            React.createElement(Button, { onClick: handleSubmit, color: "primary", variant: "contained" }, "Submit"),
            React.createElement(Button, { onClick: handleBack }, "Back")))));
}
;
