import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from "@mui/material";
import { EmailIcon, ExitIcon } from '../constants/formIcons.js';
import EmailSignInLink from './EmailSignInLink.js';
import { useAuthContext } from './AuthProvider.js';
export default function AuthForm() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [showEmailLinkDialog, setEmailLinkDialog] = useState(false);
    const authContext = useAuthContext();
    const signedOutDialog = (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            width: '75%',
            padding: 2
        } },
        React.createElement(Typography, { variant: "subtitle1", display: "block" }, "Need to track your past simulations? Sign in via passwordless email link"),
        React.createElement(Box, { sx: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            } }, !showEmailLinkDialog &&
            React.createElement(IconButton, { "aria-label": "Passwordless link", onClick: () => setEmailLinkDialog(!showEmailLinkDialog), sx: { width: '36px', height: '36px' } },
                React.createElement(EmailIcon, { style: { width: '36px', height: '36px' } }))),
        showEmailLinkDialog &&
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%'
                } },
                React.createElement(EmailSignInLink, null))));
    const signedInDialog = (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            width: '75%',
            padding: 2
        } },
        React.createElement(Typography, { variant: "subtitle1", display: "block" },
            "Signed in as ",
            authContext.currentUser?.displayName ?
                authContext.currentUser?.displayName :
                authContext.currentUser?.email,
            ".",
            React.createElement(IconButton, { onClick: handleSignOut },
                React.createElement(ExitIcon, null)))));
    useEffect(() => {
        if (!authContext.currentUser ||
            !Object.keys(authContext.currentUser).length ||
            authContext.currentUser.isAnonymous) {
            setIsSignedIn(false);
            return;
        }
        ;
        setIsSignedIn(true);
    }, [authContext]);
    function handleSignOut() {
        try {
            authContext.logout();
        }
        catch (e) {
            throw new Error(`Issues signing out user ${authContext.currentUser?.uid}: ${e}`);
        }
        ;
    }
    ;
    return (
    // <Stack direction="column" spacing={2} sx={{ mt: 2, justifyContent: 'center' }}>
    React.createElement(React.Fragment, null, isSignedIn ? signedInDialog : signedOutDialog)
    // </Stack>
    );
}
;
