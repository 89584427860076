import React, { useEffect, useState } from 'react';
import { List, ListItem, Typography, Box } from '@mui/material';
import { useLocations } from './LocationListProvider.js';
function formatDuration(duration) {
    const parts = [];
    if (duration.days > 0)
        parts.push(`${duration.days} d`);
    if (duration.hours > 0)
        parts.push(`${duration.hours} hr`);
    if (duration.minutes > 0)
        parts.push(`${duration.minutes} min`);
    return parts.join(' ');
}
export function formatDateTime(input, is24Hours, startTime) {
    const inputDate = new Date(input);
    const startDate = startTime ? new Date(startTime) : null;
    const timeZoneMatch = input.match(/(Z|[+-]\d{2}:\d{2})$/);
    const timeZone = timeZoneMatch ? (timeZoneMatch[0] === 'Z' ? 'UTC' : timeZoneMatch[0]) : 'UTC';
    if (startDate && inputDate.toDateString() !== startDate.toDateString()) {
        const options = { timeZone, month: 'short', day: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(inputDate);
    }
    else {
        const options = {
            timeZone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: !is24Hours
        };
        return new Intl.DateTimeFormat('en-US', options).format(inputDate);
    }
}
;
export default function LocationList() {
    const locations = useLocations();
    const [isDesktop, setIsDesktop] = useState(true);
    useEffect(() => {
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const mobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        const tablet = /Tablet|iPad/i.test(userAgent);
        setIsDesktop(!mobile && !tablet);
    }, [isDesktop]);
    return (React.createElement(React.Fragment, null, isDesktop ?
        React.createElement(List, { sx: { width: '100%' } }, locations.map((location, index) => (React.createElement(ListItem, { key: index, divider: true, sx: { display: 'flex', alignItems: 'center', width: '100%' } },
            location.type === 'visit' && (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', flex: '1', justifyContent: 'flex-start' } }, location.index &&
                React.createElement(Typography, { variant: "caption", sx: { ml: 1 } }, location.index))),
            location.type === 'visit' && location.time &&
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', flex: '1', alignItems: 'center' } },
                    React.createElement(Typography, { variant: "body2" }, formatDateTime(location.time.arrivalTime)),
                    React.createElement(Typography, { variant: "body2" }, formatDateTime(location.time.departureTime))),
            location.type === 'visit' &&
                React.createElement(Typography, { variant: "body1", sx: { flex: '3' } }, location.streetAddress),
            location.type === 'visit' &&
                React.createElement(Typography, { variant: "body1", sx: { flex: '1' } },
                    "\u2744\uFE0F ",
                    location.snowDepth ? location.snowDepth : 0,
                    " cm"),
            location.type === 'visit' &&
                React.createElement(Typography, { variant: "body1", sx: { flex: '1' } },
                    "$",
                    location.revenue ? location.revenue : 0),
            location.type === 'transition' && (React.createElement(Typography, { variant: "body1", sx: { flex: '1' } }, formatDuration(location.duration).length ?
                `Travel duration: ${formatDuration(location.duration)}` :
                'No travel'))))))
        :
            React.createElement(List, { sx: { width: '100%' } }, locations.map((location, index) => (React.createElement(ListItem, { key: index, divider: true, sx: { display: 'flex', alignItems: 'center', width: '100%' } },
                location.type === 'visit' && (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', flex: '0.75', justifyContent: 'flex-start' } }, location.index &&
                    React.createElement(Typography, { variant: "caption", sx: { ml: 1 } }, location.index))),
                location.type === 'visit' && location.time &&
                    React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', flex: '1', alignItems: 'center' } },
                        React.createElement(Typography, { variant: "body2" }, formatDateTime(location.time.arrivalTime)),
                        React.createElement(Typography, { variant: "body2" }, formatDateTime(location.time.departureTime))),
                location.type === 'visit' &&
                    React.createElement(Typography, { variant: "body1", sx: { flex: '3' } }, location.streetAddress),
                location.type === 'visit' &&
                    React.createElement(Typography, { variant: "body1", sx: { flex: '1.5' } },
                        "\u2744\uFE0F ",
                        location.snowDepth ? location.snowDepth : 0,
                        " cm"),
                location.type === 'visit' &&
                    React.createElement(Typography, { variant: "body1", sx: { flex: '0.5' } },
                        "$",
                        location.revenue ? location.revenue : 0),
                location.type === 'transition' && (React.createElement(Typography, { variant: "body1", sx: { flex: '1' } }, formatDuration(location.duration).length ?
                    `Travel duration: ${formatDuration(location.duration)}` :
                    'No travel'))))))));
}
;
