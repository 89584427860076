import React, { useEffect } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

import './App.css';
import Map from './components/Map.js';
import MapProvider from './components/MapProvider.js';
// import SearchBar from './components/SearchBar.js';
import VerticalLinearStepper from './components/Stepper.js';
import DeviceDetect from './components/DeviceDetect.js';
import { useAuthContext } from './components/AuthProvider.js';

function App() {
  // TODO: Remove all auth code
  const authContext = useAuthContext();
  const { isMobile, isTablet } = DeviceDetect();
  const isDesktop = !isMobile && !isTablet;
  useEffect(() => {
    if (!Object.keys(authContext).length || !authContext.currentUser) return;
    console.log([authContext.currentUser['uid'], authContext.idToken]);
  }, [authContext]);

  return (
    <div className="App">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <div className="left-panel"
          style={isDesktop ? {} : { visibility: 'hidden', maxWidth: 0 }}
        >
          {/* <SearchBar /> */}
          <MapProvider>
            <Map />
          </MapProvider>
        </div>
        <div className="right-panel">
          <VerticalLinearStepper />
        </div>
      </APIProvider>

    </div>
  );
}

export default App;
