import { useEffect } from 'react';
import axios from 'axios';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import { useAuthContext } from './AuthProvider.js';
import { SIMULATION_STATUS } from '../constants/simulationStatus.js';
import { baseURL } from '../constants/routes.js';
import { useAppDispatch } from '../redux/hooks.js';
import { setResult, setStatus } from '../redux/simulationSlice.js';
export function fetchSolutionThunk(solutionUrl, uid, idToken) {
    return async function requestCloudFleetSolution(dispatch) {
        try {
            const response = await axios.post(`${baseURL}/simulation-solution`, {
                uid,
                idToken,
                solutionUrl
            });
            if (response.status === 200) {
                const optimizeToursResponse = response.data.optimizationResponse;
                dispatch(setResult(optimizeToursResponse));
            }
        }
        catch (e) {
            throw new Error(`Failed to fetch solution ${solutionUrl} with error ${e}`);
        }
    };
}
;
export default function FirestoreListener({ simulationId, collectionId }) {
    const dispatch = useAppDispatch();
    const authContext = useAuthContext();
    useEffect(() => {
        if (!authContext || !authContext.currentUser || !authContext.idToken || !authContext.app) {
            return;
        }
        const db = getFirestore(authContext.app);
        const collectionRef = collection(db, collectionId);
        const q = query(collectionRef, where('simulationId', '==', simulationId), where('status', 'in', [SIMULATION_STATUS.requestSuccessful, SIMULATION_STATUS.requestFailed]));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    dispatch(setStatus(data.status));
                    if (data.status === SIMULATION_STATUS.requestSuccessful) {
                        if (!data.solutionUrl) {
                            throw new Error(`Successful simulation but no solutionUrl field in collection for ID ${simulationId}, URL ${data.solutionUrl}`);
                        }
                        dispatch(fetchSolutionThunk(data.solutionUrl, authContext.currentUser.uid, authContext.idToken));
                    }
                    else if (data.status === SIMULATION_STATUS.requestFailed) {
                        throw new Error(`Failed simulation for ID ${simulationId}`);
                    }
                });
                unsubscribe();
            }
        });
        return () => unsubscribe();
    }, [authContext, dispatch, collectionId, simulationId]);
    return null;
}
