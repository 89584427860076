import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selectedTimeInISO8601: ''
};
export const datetimeSlice = createSlice({
    name: 'datetime',
    initialState,
    reducers: {
        setDateTimeInISO8601: (state, action) => {
            state.selectedTimeInISO8601 = action.payload;
        },
    },
});
const { setDateTimeInISO8601 } = datetimeSlice.actions;
export { setDateTimeInISO8601 };
export default datetimeSlice.reducer;
