import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import dayjs from 'dayjs';
import { useWeatherGraphContext } from './WeatherGraphProvider.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);
export default function WeatherGraph() {
    const weatherGraphContext = useWeatherGraphContext();
    if (!weatherGraphContext)
        return React.createElement("p", null, "No data available.");
    const labels = weatherGraphContext.map((MMQueryResult, idx) => {
        const date = dayjs(MMQueryResult.time_in_utc);
        const label = date.format(date.hour() === 0 ? 'MM/DD HH:mm' : 'HH:mm');
        return label;
    });
    const formattedData = {
        labels: labels,
        datasets: [
            {
                label: 'Snow Depth (cm)',
                data: weatherGraphContext.map(MMQueryResult => MMQueryResult.snow_depth_in_cm),
                fill: true,
                backgroundColor: 'rgba(136, 132, 216, 0.2)',
                borderColor: 'rgba(136, 132, 216, 1)',
            },
        ],
    };
    return (React.createElement("div", null,
        React.createElement(Line, { data: formattedData, options: { responsive: true, maintainAspectRatio: false } })));
}
;
