import React, { useEffect, useState } from 'react';
import { AdvancedMarker, InfoWindow, Pin } from '@vis.gl/react-google-maps';

import { useMapContext } from './MapProvider.js';

export default function LocationMarker({ streetAddress, latLng, polylineHexColor, pinDisplay }) {
    const mapContext = useMapContext();
    const { isGlobalInfoWindowOpen, setGlobalInfoWindowOpen } = mapContext;
    const { setCenter } = mapContext;
    const [isInfoWindowOpen, setInfoWindowOpen] = useState(false);

    useEffect(() => {
        if (!isGlobalInfoWindowOpen) {
            setInfoWindowOpen(false);
        }
    }, [isGlobalInfoWindowOpen]);

    async function handleMarkerClick() {
        // close button on info window has different handler
        if (isGlobalInfoWindowOpen) {
            await setGlobalInfoWindowOpen(false);
        };
        setInfoWindowOpen(true);
        setGlobalInfoWindowOpen(true);
        setCenter(latLng);
    }

    function handleInfoWindowCloseClick() {
        setInfoWindowOpen(false);
        setGlobalInfoWindowOpen(false);
    }

    return (
        <AdvancedMarker position={latLng}
            onClick={handleMarkerClick}
        >
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <Pin
                    background={'#b2f7ef'} // Ice blue background
                    borderColor={'#67e8f9'} // Dark ice blue border
                    glyphColor={'#60d98f'}
                >
                    <div style={{
                        backgroundColor: polylineHexColor,
                        borderRadius: '50%',
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '12px',
                    }}>
                        {pinDisplay}
                    </div>
                </Pin>
                <span style={{
                    position: 'absolute',
                    top: '50%',  // Adjust these values as needed to center the number
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#fff',  // Ensure the number is visible against the pin background
                    fontSize: '12px',  // Adjust the font size as needed
                    fontWeight: 'bold',
                    zIndex: 333,
                }}>
                </span>
                {isInfoWindowOpen && (
                    <InfoWindow
                        className={`info-window`}
                        onCloseClick={() => handleInfoWindowCloseClick()}
                        position={latLng}
                    >
                        <p>
                            {streetAddress}
                        </p>
                    </InfoWindow>
                )}
            </div>
        </AdvancedMarker >
    );
};