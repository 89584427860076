import { createSlice } from '@reduxjs/toolkit';
const SAMPLE_KITCHENER_LOCATIONS = [
    {
        "streetAddress": "66 RIDGEMOUNT ST|Kitchener, Ontario N2P0J3",
        "lat": 43.3758118,
        "lng": -80.4178948
    },
    {
        "streetAddress": "453 THOMAS SLEE Drive|Kitchener, Ontario N2P2X6",
        "lat": 43.37483813,
        "lng": -80.43018705
    },
    {
        "streetAddress": "66 RIDGEMOUNT Street|Kitchener, Ontario N2P0J3",
        "lat": 43.3757353,
        "lng": -80.41785904
    },
    {
        "streetAddress": "164 GENERAL Drive|Kitchener, Ontario N2K3S6",
        "lat": 43.47832326,
        "lng": -80.48832669
    },
    {
        "streetAddress": "614 COPPERCROFT Court|Waterloo, Ontario N2K3M2",
        "lat": 43.50376055,
        "lng": -80.52427007
    },
    {
        "streetAddress": "17 RIVERVIEW DR|Cambridge, Ontario N1S3N7",
        "lat": 43.34392331,
        "lng": -80.32843776
    },
    {
        "streetAddress": "43 FOURTH AVE|Kitchener, Ontario N2C1N9",
        "lat": 43.4328735,
        "lng": -80.4533383
    },
    {
        "streetAddress": "43 FOURTH Avenue|Kitchener, Ontario N2C1N9",
        "lat": 43.43424584,
        "lng": -80.45298987
    },
    {
        "streetAddress": "456 RED RIVER Drive|Waterloo, Ontario N2T1V1",
        "lat": 43.44507353,
        "lng": -80.54990927
    },
    {
        "streetAddress": "50 MARGARET Avenue|Waterloo, Ontario N2J3P6",
        "lat": 43.47379221,
        "lng": -80.50486634
    },
    {
        "streetAddress": "136 MACGREGOR Crescent|Waterloo, Ontario N2J3W9",
        "lat": 43.4818457,
        "lng": -80.51439107
    },
    {
        "streetAddress": "15 KENLEY Lane|Cambridge, Ontario N1S4Z1",
        "lat": 43.35410091,
        "lng": -80.33612576
    },
    {
        "streetAddress": "220 COLTON CIRC|Kitchener, Ontario N2A4K2",
        "lat": 43.4501242,
        "lng": -80.4081948
    },
    {
        "streetAddress": "125 GORDON ST|Cambridge, Ontario N1S4K7",
        "lat": 43.3409749,
        "lng": -80.328165
    },
    {
        "streetAddress": "45 PIONEER TOWER CRES|Kitchener, Ontario N2P2L2",
        "lat": 43.4043653,
        "lng": -80.4217916
    },
    {
        "streetAddress": "265 DEER RIDGE Drive|Kitchener, Ontario N2P2K6",
        "lat": 43.40564109,
        "lng": -80.41428733
    },
    {
        "streetAddress": "50 MARGARET AVE N|Waterloo, Ontario N2J3P6",
        "lat": 43.4738009,
        "lng": -80.5048793
    },
    {
        "streetAddress": "459 DEER RIDGE DR|Kitchener, Ontario N2P0A7",
        "lat": 43.4037881,
        "lng": -80.4134634
    },
    {
        "streetAddress": "71 DOLMAN ST|Woolwich, Ontario N0B1M0",
        "lat": 43.4776358,
        "lng": -80.4195443
    },
    {
        "streetAddress": "414 RIVERTRAIL AVE|Kitchener, Ontario N2A0H6",
        "lat": 43.4500654,
        "lng": -80.3985478
    },
    {
        "streetAddress": "500A REDFOX Road|Waterloo, Ontario N2K2V8",
        "lat": 43.49718327,
        "lng": -80.51843339
    },
    {
        "streetAddress": "45 PIONEER TOWER Crescent|Kitchener, Ontario N2P2L2",
        "lat": 43.40437059,
        "lng": -80.42207055
    },
    {
        "streetAddress": "433 TWINLEAF Street|Waterloo, Ontario N2V0B5",
        "lat": 43.45367409,
        "lng": -80.5948489
    },
    {
        "streetAddress": "509 BEAVER CREEK Road|Waterloo, Ontario N2V2L3",
        "lat": 43.47506684,
        "lng": -80.58157735
    },
    {
        "streetAddress": "752 BRANDENBURG Boulevard|Waterloo, Ontario N2T2W9",
        "lat": 43.45602519,
        "lng": -80.57815573
    },
    {
        "streetAddress": "178 EDEN OAK Trail|Kitchener, Ontario N2A0H6",
        "lat": 43.4504293,
        "lng": -80.40301017
    },
    {
        "streetAddress": "616 STONEBURY Crescent|Waterloo, Ontario N2K3R1",
        "lat": 43.50511016,
        "lng": -80.52126003
    },
    {
        "streetAddress": "125 ELMWOOD AVE|Cambridge, Ontario N1R4Y8",
        "lat": 43.3757597,
        "lng": -80.3158727
    },
    {
        "streetAddress": "85 PAIGE ST|Kitchener, Ontario N2K4P6",
        "lat": 43.4759931,
        "lng": -80.4655341
    }
];
const initialState = {
    // TODO: set to [] when done testing
    locations: SAMPLE_KITCHENER_LOCATIONS,
    route: {
        locationList: [],
    },
};
export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocations: (state, action) => {
            state.locations = action.payload;
        },
        // Route
        setEncodedPolylines: (state, action) => {
            if (state.route) {
                state.route.encodedPolylineForRoute = action.payload;
            }
        },
        setEncodedPolylinesForTransitions: (state, action) => {
            if (state.route) {
                state.route.encodedPolylineForRouteTransitions = action.payload;
            }
        },
        setPolylineHexColor: (state, action) => {
            if (state.route) {
                state.route.polylineHexColor = action.payload;
            }
        },
        setLocationList: (state, action) => {
            if (state.route) {
                state.route = {
                    locationList: action.payload,
                };
            }
        },
        // weather
        handleWeatherEventsResponse: (state, action) => {
            if (state.weather) {
                state.weather.weatherEvents = action.payload.weatherEvents;
                state.weather.contiguousWeatherEvents = action.payload.contiguousWeatherEvents;
            }
            else {
                state.weather = {
                    weatherEvents: action.payload.weatherEvents,
                    contiguousWeatherEvents: action.payload.contiguousWeatherEvents,
                };
            }
        }
    },
});
const { handleWeatherEventsResponse, setEncodedPolylines, setEncodedPolylinesForTransitions, setPolylineHexColor, setLocationList } = locationSlice.actions;
export { handleWeatherEventsResponse, setEncodedPolylines, setEncodedPolylinesForTransitions, setPolylineHexColor, setLocationList };
export default locationSlice.reducer;
