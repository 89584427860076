import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../redux/hooks.js';
const WeatherGraphContext = createContext([]);
// TODO: Convert to ESM
export const WeatherGraphProvider = ({ children, center }) => {
    const weatherData = useAppSelector((state) => state.location.weather?.weatherEvents) || null;
    const [centerWeatherData, setCenterWeatherData] = useState([]);
    useEffect(() => {
        if (!Object.keys(center).length)
            return;
        let centerCoords = `${center.lat().toFixed(2)}_${center.lng().toFixed(2)}`;
        if (!weatherData || !weatherData.get(centerCoords) || !weatherData.get(centerCoords)?.length) {
            setCenterWeatherData([]);
            return;
        }
        setCenterWeatherData(weatherData.get(centerCoords) || []);
    }, [center, weatherData]);
    return (React.createElement(WeatherGraphContext.Provider, { value: centerWeatherData }, children));
};
export function useWeatherGraphContext() {
    return useContext(WeatherGraphContext);
}
;
