import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    currentSimulationId: null,
    distanceUnit: 'km',
    locale: 'en',
    simulations: [],
    temperatureUnit: 'C',
    timeFormat: 'MMM DD hh:mm A',
};
export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {},
});
export default userSlice.reducer;
