import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useMapsLibrary, useMap } from '@vis.gl/react-google-maps';
import Grid from '@mui/material/Grid/Grid.js';
import { Button, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.js';
import dayjs from "dayjs";
import WeatherGraph from './WeatherGraph.js';
import { WeatherGraphProvider } from './WeatherGraphProvider.js';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { setReferenceLocation, setRequest, setSnowDepthTrigger } from '../redux/simulationSlice.js';
import { validateLocation } from '../resources/validateSiteEligibility.js';
import { baseURL } from '../constants/routes.js';
import { handleWeatherEventsResponse } from '../redux/locationSlice.js';
import { useAuthContext } from './AuthProvider.js';
export default function StepperGlobalTimeWindows({ handleNext, handleBack }) {
    const authContext = useAuthContext();
    const map = useMap();
    const placesLib = useMapsLibrary('places');
    const EARLIEST_SIMULATION_START_DATE = dayjs().subtract(5, 'year').add(1, 'day');
    const LATEST_SIMULATION_START_DATE = dayjs().add(5, 'day');
    const textFieldRef = useRef(null);
    const [locationInput, setLocationInput] = useState('');
    const [centerCoords, setCenterCoords] = useState();
    const referenceLocation = useAppSelector((state) => state.simulation.referenceLocation);
    const simulationRequest = useAppSelector((state) => state.simulation.request);
    const [globalStartTime, setGlobalStartTime] = useState(null);
    const [globalEndTime, setGlobalEndTime] = useState(null);
    const [shouldDisplayGraph, setShouldDisplayGraph] = useState(false);
    // Text validation slot props
    const [isInvalidLocation, setIsInvalidLocation] = useState(false);
    const [isInvalidGlobalStartTime, setIsInvalidGlobalStartTime] = useState(false);
    const [isInvalidGlobalEndTime, setIsInvalidGlobalEndTime] = useState(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        // Hook for creating new simulation if it doesn't exist already
        if (!simulationRequest || !Object.keys(simulationRequest).length) {
            const emptyModel = {
                shipments: [],
                vehicles: [],
                globalStartTime: '',
                globalEndTime: ''
            };
            const emptySimulationRequest = {
                optimizationRequest: {
                    parent: '',
                    timeout: '',
                    model: emptyModel,
                    injectedSolutionConstraint: {},
                }
            };
            dispatch(setRequest(emptySimulationRequest));
            dispatch(setSnowDepthTrigger(0));
        }
        else {
            // Re-hydrate step with existing simulation details
            if (simulationRequest.optimizationRequest?.model.globalStartTime &&
                simulationRequest.optimizationRequest?.model.globalEndTime) {
                setGlobalStartTime(dayjs(simulationRequest.optimizationRequest?.model.globalStartTime));
                setGlobalEndTime(dayjs(simulationRequest.optimizationRequest?.model.globalEndTime));
            }
            if (referenceLocation) {
                setLocationInput(referenceLocation.streetAddress);
                const serializedCoords = new window.google.maps.LatLng(referenceLocation.coords.lat, referenceLocation.coords.lng);
                setCenterCoords(serializedCoords);
            }
        }
    }, [dispatch, referenceLocation, simulationRequest]);
    useEffect(() => {
        // Hook for populating weather in location store when autocomplete finishes.
        if (!centerCoords || !globalStartTime || !globalEndTime || !authContext || !authContext.currentUser) {
            return;
        }
        const weatherEventsRequest = {
            startTime: globalStartTime.toISOString(),
            endTime: globalEndTime.toISOString(),
            locations: [{ lat: parseFloat(centerCoords.lat().toFixed(2)), lng: parseFloat(centerCoords.lng().toFixed(2)) }],
            snowDepthTrigger: 0,
        };
        const populateWeatherStore = async () => {
            try {
                const response = await axios.post(`${baseURL}/fetch-weather-events`, {
                    ...weatherEventsRequest,
                    uid: authContext.currentUser['uid'],
                    idToken: authContext.idToken,
                });
                if (response.status === 200) {
                    console.log(response.data);
                    console.log(typeof response.data);
                    if (response.data && !response.data.weatherEvents?.length) {
                        throw new Error(`Received data from /fetch-weather-events but no weather events: ${JSON.stringify(response.data)}`);
                    }
                    if (response.data && !response.data.contiguousWeatherEvents?.length) {
                        throw new Error(`Received data from /fetch-weather-events but no contiguous weather events : ${JSON.stringify(response.data)}`);
                    }
                    try {
                        const weatherEventsMap = new Map(response.data.weatherEvents);
                        const contiguousWeatherEventsMap = new Map(response.data.contiguousWeatherEvents);
                        const weatherEvents = weatherEventsMap;
                        const contiguousWeatherEvents = contiguousWeatherEventsMap;
                        const weatherEventsResponse = { weatherEvents, contiguousWeatherEvents };
                        handleWeatherEventsResponse(weatherEventsResponse);
                        setShouldDisplayGraph(true);
                    }
                    catch (e) {
                        throw new Error(`Unable to convert weatherEvents from response into a map!: ${response.data.weatherEvents}`);
                    }
                }
            }
            catch (e) {
                throw new Error(`Failed to fetch weather events for ${JSON.stringify(weatherEventsRequest)}: ${e}`);
            }
        };
        if (process.env.SHOW_WEATHER_GRAPH) {
            populateWeatherStore();
        }
    }, [centerCoords, globalStartTime, globalEndTime, authContext]);
    const handleTextFieldChange = useCallback((newTextFieldValue) => {
        setLocationInput(newTextFieldValue);
        if (!placesLib || !map || !textFieldRef.current)
            return;
        const autocompleteService = new placesLib.Autocomplete(textFieldRef.current, {
            bounds: map.getBounds(),
            fields: ["formatted_address", "geometry.viewport", "geometry.location"],
            types: ['geocode'],
        });
        const updateAutocompleteBounds = () => {
            const bounds = map.getBounds();
            autocompleteService.setBounds(bounds);
        };
        const onPlaceChanged = () => {
            const place = autocompleteService.getPlace();
            if (place.formatted_address && place.geometry?.viewport && place.geometry.location) {
                map.fitBounds(place.geometry.viewport);
                const isEligibleLocation = validateLocation(place.geometry.location);
                if (!isEligibleLocation) {
                    throw new Error("Location isn't available for service");
                }
                setCenterCoords(place.geometry.location);
                setLocationInput(place.formatted_address);
            }
        };
        autocompleteService.addListener('place_changed', onPlaceChanged);
        map.addListener('bounds_changed', updateAutocompleteBounds);
        // Cleanup function to remove the listeners
        return () => {
            window.google.maps.event.clearInstanceListeners(autocompleteService);
            window.google.maps.event.clearInstanceListeners(map);
        };
    }, [placesLib, map]);
    const handleSubmit = useCallback(() => {
        if (!simulationRequest) {
            throw new Error(`No simulation request found`);
        }
        // Check: if missing start/stop times, flag error in the DateTimePicker
        if (!locationInput) {
            setIsInvalidLocation(true);
            return;
        }
        if (!globalStartTime) {
            setIsInvalidGlobalStartTime(true);
            return;
        }
        if (!globalEndTime) {
            setIsInvalidGlobalEndTime(true);
            return;
        }
        const model = simulationRequest.optimizationRequest.model;
        dispatch(setRequest({
            optimizationRequest: {
                ...simulationRequest.optimizationRequest,
                model: {
                    ...model,
                    globalStartTime: globalStartTime.toISOString(),
                    globalEndTime: globalEndTime.toISOString(),
                }
            }
        }));
        if (!centerCoords || !locationInput)
            return;
        dispatch(setReferenceLocation({
            streetAddress: locationInput,
            coords: {
                lat: centerCoords.lat(),
                lng: centerCoords.lng(),
            }
        }));
        handleNext();
    }, [dispatch, centerCoords, handleNext, locationInput, simulationRequest, globalEndTime, globalStartTime]);
    return (React.createElement(Grid, { container: true, spacing: 3 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Stack, { spacing: 1.5 },
                React.createElement(TextField, { autoComplete: "off", error: isInvalidLocation, helperText: isInvalidLocation ? 'Missing city/location' : null, fullWidth: true, inputRef: textFieldRef, value: locationInput, onChange: (e) => {
                        handleTextFieldChange(e.target.value);
                        setIsInvalidLocation(false);
                    }, type: "text", placeholder: 'Enter city or location', sx: { height: '40px', paddingBottom: '30px' } }),
                React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                    React.createElement(DateTimePicker
                    // Date pickers render in local timezone, read/write in UTC
                    , { 
                        // Date pickers render in local timezone, read/write in UTC
                        slotProps: {
                            textField: {
                                error: isInvalidGlobalStartTime,
                                helperText: isInvalidGlobalStartTime ? 'Missing/invalid global start time' : null,
                            },
                        }, label: "When would you like the simulation to begin?", value: globalStartTime, onChange: (e) => {
                            setGlobalStartTime(e);
                            setIsInvalidGlobalStartTime(false);
                        }, minDateTime: EARLIEST_SIMULATION_START_DATE, maxDateTime: LATEST_SIMULATION_START_DATE })),
                globalStartTime && (React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                    React.createElement(DateTimePicker, { slotProps: {
                            textField: {
                                error: isInvalidGlobalEndTime,
                                helperText: isInvalidGlobalEndTime ? 'Missing/invalid global end time' : null,
                            },
                        }, label: "When would you like the simulation to end?", value: globalEndTime, onChange: (e) => {
                            setGlobalEndTime(e);
                            setIsInvalidGlobalEndTime(false);
                        }, minDateTime: globalStartTime.add(1, 'day'), maxDateTime: globalStartTime.add(5, 'day') }))),
                shouldDisplayGraph && (React.createElement(WeatherGraphProvider, { center: centerCoords },
                    React.createElement(WeatherGraph, null))))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Stack, { direction: "row", spacing: 2, justifyContent: "center" },
                React.createElement(Button, { onClick: handleSubmit, color: "primary", variant: "contained" }, "Submit"),
                React.createElement(Button, { onClick: handleBack, color: "secondary" }, "Back")))));
}
;
