import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useAppSelector } from '../redux/hooks.js';
export const MapContext = createContext({});
export default function MapProvider({ children }) {
    const map = useMap();
    const mapsLibrary = useMapsLibrary('geometry');
    // Simulation + Location store selectors
    const routeLocationListRows = useAppSelector((state) => state.location.route?.locationList);
    // Polyline selectors
    const maybeEncodedPolylinesForRoute = useAppSelector((state) => state.location.route?.encodedPolylineForRoute);
    const maybeEncodedPolylinesForTransitions = useAppSelector((state) => state.location.route?.encodedPolylineForRouteTransitions);
    const polylineHexColor = useAppSelector((state) => state.location.route?.polylineHexColor);
    const [visits, setVisits] = useState([]);
    const [polylinePaths, setPolylinePaths] = useState([]);
    const [isGlobalInfoWindowOpen, setGlobalInfoWindowOpen] = useState(false);
    const [center, setCenter] = useState({
        lat: 43.43424584,
        lng: -80.45298987,
    });
    useEffect(() => {
        // This hook only runs in simulation!!!
        if (!routeLocationListRows || routeLocationListRows.length === 0) {
            setVisits([]);
            return;
        }
        const orderedVisits = routeLocationListRows.filter((route) => route.type === 'visit');
        setVisits(orderedVisits);
    }, [routeLocationListRows]);
    useEffect(() => {
        if (!map || !mapsLibrary)
            return;
        // No need for encoded polyline for transitions
        let encodedPolylines = maybeEncodedPolylinesForRoute ? [maybeEncodedPolylinesForRoute] : [];
        const polylines = [];
        function createPolyline(encodedPolylines) {
            encodedPolylines.forEach(encodedPolyline => {
                if (!encodedPolyline.points) {
                    throw new Error(`No points found for polyline!`);
                }
                const points = mapsLibrary.encoding.decodePath(encodedPolyline.points);
                const path = points.map(point => new window.google.maps.LatLng(point.lat(), point.lng()));
                const polyline = new window.google.maps.Polyline({
                    path: path,
                    geodesic: true,
                    strokeColor: polylineHexColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });
                polylines.push(polyline);
            });
        }
        if (visits.length >= 2) {
            createPolyline(encodedPolylines);
            setPolylinePaths(polylines);
        }
        ;
    }, [map, mapsLibrary, maybeEncodedPolylinesForRoute, maybeEncodedPolylinesForTransitions, polylineHexColor, visits]);
    const mapContextValue = {
        center,
        isGlobalInfoWindowOpen: isGlobalInfoWindowOpen,
        polylineHexColor,
        polylinePaths,
        setCenter: setCenter,
        setGlobalInfoWindowOpen: setGlobalInfoWindowOpen,
        visits,
    };
    return (React.createElement(MapContext.Provider, { value: mapContextValue }, children));
}
;
export function useMapContext() {
    return useContext(MapContext);
}
;
