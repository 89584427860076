import { useState, useEffect } from 'react';
export default function DeviceDetect() {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    useEffect(() => {
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const mobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        const tablet = /Tablet|iPad/i.test(userAgent);
        setIsMobile(mobile);
        setIsTablet(tablet);
    }, []);
    return { isMobile, isTablet };
}
;
