import dayjs from "dayjs";
/*
Snowfall in the hour before deadline should be strictly below the threshold.

1. Filter for non-continuous results from the location store (request)
2. Find relevant overlapping weather event block, if d.n.e, then discount that visit from the list
3. For the relevant weather block, starting at the start time, do an aggregated snow depth at each hour.
4. At the aggregated total at the deadline, figure out which is the latest time where the agg. snow depth is (agg snow depth at deadline) - snow depth trigger. This is the start time, the end time is the deadline.
*/
export default function generateRequestTimeWindow(visit, weatherEvents, queryResults) {
    let timeWindow;
    try {
        timeWindow = visit.timeWindows[0];
    }
    catch (e) {
        throw new Error(`Failed to find valid time window in visit`);
    }
    const globalStartTime = dayjs(timeWindow.startTime);
    const deadline = dayjs(timeWindow.endTime);
    const duration = parseInt(visit.duration.split('s')[0], 10);
    const relevantWeatherEvent = weatherEvents.filter(weatherEvent => {
        const eventStartTime = dayjs(weatherEvent.startTime);
        const eventEndTime = dayjs(weatherEvent.endTime);
        return eventStartTime.isBefore(deadline) && eventEndTime.isAfter(deadline);
    });
    if (!relevantWeatherEvent.length) {
        // No snow above threshold at the time
        return false;
    }
    if (relevantWeatherEvent.length > 1) {
        throw new Error('More than one weather events found for the deadline!');
    }
    const snowDepthTrigger = relevantWeatherEvent[0].snowDepthTrigger;
    const snowDepthAtTime = {};
    queryResults.forEach((queryResult) => {
        const time_in_utc = queryResult.time_in_utc;
        const time = dayjs(time_in_utc);
        if ((deadline.isAfter(time) || deadline.isSame(time)) &&
            (globalStartTime.isBefore(time) || globalStartTime.isSame(time))) {
            snowDepthAtTime[time_in_utc] = queryResult.snow_depth_in_cm;
        }
    });
    function findEarliestTimeBeforeDeadline(snowDepthAtTime, deadline, threshold) {
        // iterate from deadine to beginning -- find the earliest hour where total snowfall 
        // doesn't exceed snow depth threshold.
        let earliestTime = null;
        const times = Object.keys(snowDepthAtTime);
        for (let i = times.length - 1; i >= 0; i--) {
            const time = times[i];
            const expectedSnowfall = snowDepthAtTime[deadline] - snowDepthAtTime[time];
            if (time >= deadline)
                continue;
            if (earliestTime && expectedSnowfall > threshold) {
                break;
            }
            if (expectedSnowfall <= threshold) {
                earliestTime = time;
            }
        }
        return earliestTime;
    }
    const earliestTime = findEarliestTimeBeforeDeadline(snowDepthAtTime, deadline.toISOString(), snowDepthTrigger);
    if (!earliestTime) {
        throw new Error('Insufficient time for snow removal due to heavy weather');
    }
    if (dayjs(deadline).diff(dayjs(earliestTime), 'second') < duration) {
        throw new Error(`Insufficient duration for snow removal due to user constraint`);
    }
    return {
        ...timeWindow,
        startTime: earliestTime,
    };
}
